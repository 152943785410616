var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "text-center" }, [
        _vm._v(_vm._s(_vm.$lang["Order Preview"])),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c(
        "form",
        {
          attrs: { autocomplete: "off" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.makeOrder.apply(null, arguments)
            },
          },
        },
        [
          _c("table", { staticClass: "table" }, [
            _c("tbody", [
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "text-center", attrs: { colspan: "2" } },
                  [_c("b", [_vm._v(_vm._s(_vm.$lang["Delivery Details"]))])]
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$lang["Delivery Address"]))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(_vm.deliveryDetails.deliveryAddress.address)),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$lang["Delivery Type"]))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        _vm.deliveryDetails.deliveryTiming.delivery_type.toUpperCase()
                      ) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.deliveryDetails.deliveryTiming.delivery_type == "preorder"
                ? _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$lang["Delivery Time"]))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.deliveryDate))]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$lang["Payment Method"]))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        _vm.deliveryDetails.paymentMethod
                          .replaceAll("_", " ")
                          .toUpperCase()
                      ) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.deliveryDetails.deliveryTiming.order_instructions
                ? _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$lang["Order Note"]))]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.deliveryDetails.deliveryTiming.order_instructions
                        )
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("table", { staticClass: "table" }, [
            _c("tbody", [
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "text-center", attrs: { colspan: "2" } },
                  [_c("b", [_vm._v(_vm._s(_vm.$lang["Order Summary"]))])]
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$lang["Sub Total"]))]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(_vm._s(_vm.$currency + " " + _vm.subTotal)),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$lang["Delivery Charge"]))]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(
                    "\n              + " +
                      _vm._s(_vm.$currency + " " + _vm.deliveryCharge) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$lang["Discount"]))]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v("- " + _vm._s(_vm.$currency + " " + _vm.discount)),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$lang["Grand Total"]))]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _c("b", [
                    _vm._v(_vm._s(_vm.$currency + " " + _vm.grandTotal)),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-block mt-2",
              attrs: { type: "submit" },
            },
            [
              _vm.loading
                ? _c("span", {
                    staticClass: "spinner-border spinner-border-sm",
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.loading
                ? _c("span", [_vm._v(_vm._s(_vm.$lang["Place Order"]))])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-light text-dark btn-block",
              attrs: { type: "button" },
              on: { click: _vm.previousStep },
            },
            [_vm._v("\n        " + _vm._s(_vm.$lang["Back"]) + "\n      ")]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }