var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isSettingsLoading
    ? _c(
        "div",
        [
          _c("header", [
            _c("div", { staticClass: "main-menu-area" }, [
              _c("div", { staticClass: "container-fluid" }, [
                _c("div", { staticClass: "d-flex align-items-center" }, [
                  _c(
                    "div",
                    { staticClass: "left-header" },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("router-link", { attrs: { to: { name: "home" } } }, [
                        _c("img", {
                          staticClass: "logo d-none d-md-inline-block",
                          attrs: { src: "/theme/img/website-logo.png" },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "search-area flex-grow-1" }, [
                    _c("div", { staticClass: "home-search" }, [
                      _c("input", {
                        attrs: {
                          type: "text",
                          name: "search",
                          id: "search",
                          autocomplete: "off",
                          placeholder: _vm.$lang["Search Products"],
                        },
                        domProps: { value: _vm.keyword },
                        on: {
                          keyup: function ($event) {
                            return _vm.searchProduct($event)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm._m(1),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "right-header ml-lg-2" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "menu-extra float-right d-none d-lg-inline-block",
                        },
                        [
                          _c("ul", [
                            _vm.isLoggedIn
                              ? _c(
                                  "li",
                                  [
                                    _c(
                                      "router-link",
                                      { attrs: { to: { name: "profile" } } },
                                      [
                                        _c("i", {
                                          staticClass: "icofont-ui-user",
                                        }),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.$lang["Account"])
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isLoggedIn
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn-signin",
                                      attrs: { href: "#" },
                                      on: { click: _vm.logout },
                                    },
                                    [
                                      _c("i", { staticClass: "icofont-exit" }),
                                      _vm._v(" " + _vm._s(_vm.$lang["Logout"])),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isLoggedIn
                              ? _c(
                                  "li",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "btn-signup",
                                        attrs: {
                                          to: { name: "register" },
                                          exact: "",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "icofont-user-alt-7",
                                        }),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.$lang["Sign Up"])
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isLoggedIn
                              ? _c(
                                  "li",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "btn-signin",
                                        attrs: {
                                          to: { name: "login" },
                                          exact: "",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "icofont-sign-in",
                                        }),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.$lang["Sign In"])
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-action btn-sm d-lg-none ml-2",
                          attrs: { to: { name: "profile" } },
                        },
                        [_c("i", { staticClass: "icofont-ui-user" })]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("ShoppingCart"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mobile-nav", attrs: { id: "content-area" } },
            [
              _c("Sidebar"),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { id: "content" } },
                [
                  _c("event-hub"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "main-content" },
                    [_c("router-view")],
                    1
                  ),
                  _vm._v(" "),
                  _c("Footer"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { attrs: { id: "sidebarToggle" } }, [
      _c("div", { staticClass: "lines" }, [
        _c("span"),
        _vm._v(" "),
        _c("span"),
        _vm._v(" "),
        _c("span"),
        _vm._v(" "),
        _c("span"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "search-btn d-none d-md-block",
        attrs: { type: "button" },
      },
      [_c("i", { staticClass: "icofont-search" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }