var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "banner" }, [
      _c("img", {
        staticClass: "page-banner",
        attrs: { src: _vm.bannerImage },
      }),
      _vm._v(" "),
      _c("h2", { staticClass: "page-title" }, [
        _vm._v(_vm._s(_vm.category.name)),
      ]),
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "popular-items section" }, [
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c("h3", { staticClass: "section-heading" }, [
            _vm._v(_vm._s(_vm.category.name)),
          ]),
          _vm._v(" "),
          _vm.loading && _vm.load_more ? _c("ProductSkelton") : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "category" }, [
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              _vm._l(_vm.category.sub_categories, function (sub_category) {
                return _c(
                  "div",
                  { key: sub_category.id, staticClass: "flex-item" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "categoryProducts",
                            params: { slug: sub_category.slug },
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "category-item" }, [
                          _c("img", { attrs: { src: sub_category.image } }),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(sub_category.name))]),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("Products", {
            attrs: { products: _vm.products, loading: _vm.loading },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }