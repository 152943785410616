var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-6 mx-auto my-5" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h4", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$lang["Order Summary"])),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _vm.order
              ? _c("table", { staticClass: "table" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.$lang["Order ID"]))]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.order.order_id)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.$lang["Payment Status"]))]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(_vm.paymentStatus) },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.$lang["Delivery Status"]))]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(_vm.deliveryStatus) },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.$lang["Payment Method"]))]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.order.payment_method
                                .replaceAll("_", " ")
                                .toUpperCase()
                            ) +
                            "\n                "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.$lang["Sub Total"]))]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.$currency + " " + _vm.order.sub_total) +
                            "\n                "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.$lang["Delivery Charge"]))]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n                  + " +
                            _vm._s(
                              _vm.$currency + " " + _vm.order.shipping_cost
                            ) +
                            "\n                "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.$lang["Discount"]))]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n                  - " +
                            _vm._s(_vm.$currency + " " + _vm.order.discount) +
                            "\n                "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.$lang["Grand Total"]))]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.$currency + " " + _vm.order.grand_total)
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.order.payment_method == "PayPal" &&
                    _vm.order.payment_status == "pending"
                      ? _c("tr", [
                          _c(
                            "td",
                            { attrs: { colspan: "2" } },
                            [_c("PayPalButton")],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.order.payment_method == "credit_card" &&
                    _vm.order.payment_status == "pending"
                      ? _c("tr", [
                          _c(
                            "td",
                            { attrs: { colspan: "2" } },
                            [_c("StripeButton")],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }