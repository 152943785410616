var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading && _vm.init
        ? _c("SidebarSkelton")
        : _c(
            "div",
            { attrs: { id: "sidebar" } },
            [
              _c(
                "router-link",
                {
                  staticClass: "nav-link",
                  attrs: { to: { name: "home" }, exact: "" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.triggerNav.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "icofont-home" }),
                  _vm._v(" " + _vm._s(_vm.$lang["Home"]) + "\n    "),
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "nav-link",
                  attrs: { to: { name: "offersProducts" }, exact: "" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.triggerNav.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "icofont-gift" }),
                  _vm._v(" " + _vm._s(_vm.$lang["Special Offers"]) + "\n    "),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.allCategories, function (category) {
                return _c("span", { key: category.id }, [
                  category.sub_categories.length && category.parent_id == null
                    ? _c("span", [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link collapsed",
                            attrs: {
                              href: "#",
                              "data-toggle": "collapse",
                              "data-target": "#" + category.slug,
                              "aria-expanded": "false",
                              "aria-controls": "collapseLayouts",
                            },
                          },
                          [
                            _c("span", {
                              domProps: { innerHTML: _vm._s(category.icon) },
                            }),
                            _vm._v(
                              " " + _vm._s(category.name) + "\n          "
                            ),
                            _c(
                              "span",
                              { staticClass: "sidenav-collapse-arrow" },
                              [_c("i", { staticClass: "icofont-rounded-down" })]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "collapse",
                            attrs: {
                              id: category.slug,
                              "data-parent": "#sidebar",
                            },
                          },
                          _vm._l(
                            category.sub_categories,
                            function (sub_category) {
                              return _c(
                                "nav",
                                {
                                  key: sub_category.id,
                                  staticClass: "sidenav-menu-nested",
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        to: {
                                          name: "categoryProducts",
                                          params: { slug: sub_category.slug },
                                        },
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.triggerNav.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(sub_category.name))]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  category.sub_categories.length == 0 &&
                  category.parent_id == null
                    ? _c(
                        "span",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                to: {
                                  name: "categoryProducts",
                                  params: { slug: category.slug },
                                },
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.triggerNav.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("span", {
                                domProps: { innerHTML: _vm._s(category.icon) },
                              }),
                              _vm._v(
                                " " + _vm._s(category.name) + "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              }),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }