var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "text-center" }, [
        _vm._v(_vm._s(_vm.$lang["Delivery Timing"])),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c(
        "form",
        {
          attrs: { autocomplete: "off" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.saveTiming.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "form-label-group mb-3" }, [
            _c("label", { attrs: { for: "order_instructions" } }, [
              _vm._v(_vm._s(_vm.$lang["Order Instructions"])),
            ]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.order_instructions,
                  expression: "order_instructions",
                },
              ],
              class:
                _vm.errors && _vm.errors.order_instructions
                  ? "form-control is-invalid"
                  : "form-control",
              attrs: {
                id: "order_instructions",
                placeholder: _vm.$lang["Order Instructions"],
              },
              domProps: { value: _vm.order_instructions },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.order_instructions = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _vm.errors && _vm.errors.order_instructions
              ? _c("span", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.errors.order_instructions[0]) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-label-group mb-3" }, [
            _c("label", { attrs: { for: "delivery_type" } }, [
              _vm._v(_vm._s(_vm.$lang["Delivery Type"]) + " *"),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.delivery_type,
                    expression: "delivery_type",
                  },
                ],
                class:
                  _vm.errors && _vm.errors.phone
                    ? "form-control is-invalid"
                    : "form-control",
                attrs: { id: "delivery_type" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.delivery_type = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "express" } }, [
                  _vm._v(_vm._s(_vm.$lang["Express Delivery"])),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "preorder" } }, [
                  _vm._v(_vm._s(_vm.$lang["Pre-Order"])),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.errors && _vm.errors.delivery_type
              ? _c("span", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.errors.delivery_type[0]) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.delivery_type == "preorder"
            ? _c(
                "div",
                { staticClass: "form-label-group mb-3" },
                [
                  _c("label", { attrs: { for: "delivery_type" } }, [
                    _vm._v(_vm._s(_vm.$lang["Delivery Date"]) + " *"),
                  ]),
                  _vm._v(" "),
                  _c("datetime", {
                    attrs: {
                      type: "datetime",
                      "min-datetime": _vm.minDatetime,
                      "input-class": "form-control",
                    },
                    model: {
                      value: _vm.delivery_date,
                      callback: function ($$v) {
                        _vm.delivery_date = $$v
                      },
                      expression: "delivery_date",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "btn btn-primary mt-2", attrs: { type: "submit" } },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$lang["Next: Payment Methods"]) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-light text-dark mt-2 float-right",
              attrs: { type: "button" },
              on: { click: _vm.previousStep },
            },
            [_vm._v("\n        " + _vm._s(_vm.$lang["Back"]) + "\n      ")]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }