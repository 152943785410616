var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "banner" }, [
      _c("img", {
        staticClass: "page-banner",
        attrs: { src: _vm.bannerImage },
      }),
      _vm._v(" "),
      _c("h2", { staticClass: "page-title" }, [
        _vm._v(_vm._s(_vm.$lang["Special Offers"])),
      ]),
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "popular-items section" }, [
      _c(
        "div",
        {
          staticClass: "container-fluid",
          attrs: { id: "popular-items-container" },
        },
        [
          _c("h3", { staticClass: "section-heading" }, [
            _vm._v(_vm._s(_vm.$lang["Special Offers"])),
          ]),
          _vm._v(" "),
          _vm.loading && _vm.load_more ? _c("ProductSkelton") : _vm._e(),
          _vm._v(" "),
          _c("Products", {
            attrs: {
              products: _vm.products,
              productType: "offer",
              offerStyle: "column-2",
              loading: _vm.loading,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }